import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/jonespen/projects/urbaninfrastructure/react-ui-kit/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from "docz";
import { Text } from "@urbaninfrastructure/react-ui-kit";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "text"
    }}>{`Text`}</h1>
    <p>{`Text is pretty much like `}<inlineCode parentName="p">{`Box`}</inlineCode>{`, but for typographic styling.`}</p>
    <h2 {...{
      "id": "default"
    }}>{`Default`}</h2>
    <Playground __position={0} __code={'<Text>\n  Cras semper nunc non purus{\' \'}\n  <Text as=\"span\" bold>\n    consequat\n  </Text>\n  , sit amet aliquet tortor vestibulum. Sed nisi sem, malesuada et fringilla\n  quis, condimentum non massa. Morbi eget varius orci. Pellentesque diam\n  mauris, egestas vel suscipit dapibus, rhoncus a enim. Quisque laoreet neque\n  et maximus malesuada. Nulla tempus enim vitae eros auctor, at posuere quam\n  viverra. Donec nec enim sit amet erat sodales molestie a et urna. Nam\n  fermentum felis eu nulla tincidunt rhoncus. Cras justo sapien, dignissim id\n  scelerisque sit amet, euismod at enim. Nulla euismod maximus enim, nec\n  dictum orci vestibulum ut. Donec euismod imperdiet arcu, sed aliquet risus\n  ullamcorper quis. Cras quis placerat elit.\n</Text>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Text,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Text mdxType="Text">
    Cras semper nunc non purus{" "}
    <Text as="span" bold mdxType="Text">
      consequat
    </Text>
    , sit amet aliquet tortor vestibulum. Sed nisi sem, malesuada et fringilla quis,
    condimentum non massa. Morbi eget varius orci. Pellentesque diam mauris, egestas
    vel suscipit dapibus, rhoncus a enim. Quisque laoreet neque et maximus malesuada.
    Nulla tempus enim vitae eros auctor, at posuere quam viverra. Donec nec enim
    sit amet erat sodales molestie a et urna. Nam fermentum felis eu nulla tincidunt
    rhoncus. Cras justo sapien, dignissim id scelerisque sit amet, euismod at enim.
    Nulla euismod maximus enim, nec dictum orci vestibulum ut. Donec euismod imperdiet
    arcu, sed aliquet risus ullamcorper quis. Cras quis placerat elit.
  </Text>
    </Playground>
    <h2 {...{
      "id": "font"
    }}>{`Font`}</h2>
    <Playground __position={1} __code={'<Text fontFamily=\"mono\">Monospaced</Text>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Text,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Text fontFamily="mono" mdxType="Text">Monospaced</Text>
    </Playground>
    <h2 {...{
      "id": "mute"
    }}>{`Mute`}</h2>
    <Playground __position={2} __code={'<Text mute>Muted text</Text>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Text,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Text mute mdxType="Text">Muted text</Text>
    </Playground>
    <h2 {...{
      "id": "small"
    }}>{`Small`}</h2>
    <Playground __position={3} __code={'<Text small>Small text</Text>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Text,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Text small mdxType="Text">Small text</Text>
    </Playground>
    <h2 {...{
      "id": "text-styles"
    }}>{`Text styles`}</h2>
    <Playground __position={4} __code={'<Text typoStyle=\"xxl\">XXL text, the quick brown fox did something</Text>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Text,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Text typoStyle="xxl" mdxType="Text">XXL text, the quick brown fox did something</Text>
    </Playground>
    <h2 {...{
      "id": "white-space"
    }}>{`White space`}</h2>
    <Playground __position={5} __code={'<Text whiteSpace=\"nowrap\">\n  No wrapped text, donec euismod imperdiet arcu, sed aliquet risus ullamcorper\n  quis. Cras quis placerat elit.\n</Text>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Text,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Text whiteSpace="nowrap" mdxType="Text">
    No wrapped text, donec euismod imperdiet arcu, sed aliquet risus ullamcorper
    quis. Cras quis placerat elit.
  </Text>
    </Playground>
    <h2 {...{
      "id": "component-props"
    }}>{`Component props`}</h2>
    <Props of={Text} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      